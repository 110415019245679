import React, { Component } from 'react'
class Stream extends Component {
  render() {
    return (
      <iframe src="https://livestream.com/accounts/12458818/events/9610217" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" />
    )
  }
}

export default Stream
